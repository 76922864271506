import { useState } from 'react';
import { QuestionHeader } from '../../components/currentQuestion/QuestionHeader';
import { LoadImage } from '../../components/load-image/LoadImage';
import { Spinner } from '../../components/spinner/Spinner';
import {
  useTakeLivePollState,
  useSubmitProfileQuestionAnswer,
  useSubmitLastProfileQuestionAnswer,
} from '../../contexts/takeLivePollStateContext';
import { IProfileQuestion } from '../../models/ProfileQuestion';
import { IProfileQuestionAnswer } from '../../models/ProfileQuestionAnswer';
import { ProfileQuestionsFinishedScreen } from '../profile-question-finished/ProfileQuestionsFinishedScreen';

import { ProfileQuestionAnswers } from './components/ProfileQuestionAnswers';
import styles from './ProfileQuestionStartedScreen.module.css';

export const ProfileQuestionStartedScreen = () => {
  const { serverState } = useTakeLivePollState();
  const profileQuestions = serverState.profileQuestions!;
  const profileQuestionsAnsweredCount =
    serverState.profileQuestionsAnsweredCount || 0;

  const [profileQuestion, setProfileQuestion] = useState<IProfileQuestion>(
    profileQuestions[profileQuestionsAnsweredCount],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadFinishProfileQuestionScreen, setLoadFinishProfileQuestionScreen] =
    useState<boolean>(false);
  const submitProfileQuestionAnswer = useSubmitProfileQuestionAnswer();
  const submitLastProfileQuestionAnswer = useSubmitLastProfileQuestionAnswer();

  if (!profileQuestion) {
    return null;
  }

  if (isLoading) {
    return <Spinner message="Loading next question" />;
  }

  if (loadFinishProfileQuestionScreen) {
    return <ProfileQuestionsFinishedScreen />;
  }

  const getCurrentProfileQuestionNumber = (profileQuestionId: string) => {
    const index = profileQuestions.findIndex(
      profileQuestion => profileQuestion.id === profileQuestionId,
    );
    return index + 1;
  };

  const isCurrentProfileQuestionIsLastProfileQuestion = (
    currentProfileQuestionId: string,
  ) => {
    const currentProfileQuestionIndex = profileQuestions.findIndex(
      profileQuestion => profileQuestion.id === currentProfileQuestionId,
    );
    if (currentProfileQuestionIndex === profileQuestions.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitProfileQuestionAnswer = async (
    answer: IProfileQuestionAnswer,
  ) => {
    setIsLoading(true);
    const data = { questionId: profileQuestion.id, answerId: answer.id };
    const isLastQuestion = isCurrentProfileQuestionIsLastProfileQuestion(
      profileQuestion.id,
    );
    const submitAnswer = isLastQuestion
      ? submitLastProfileQuestionAnswer
      : submitProfileQuestionAnswer;

    await submitAnswer(data);

    if (isLastQuestion) {
      setLoadFinishProfileQuestionScreen(true);
    } else {
      const nextQuestionIndex = getCurrentProfileQuestionNumber(
        profileQuestion.id,
      );
      setProfileQuestion(profileQuestions[nextQuestionIndex]);
    }
    setIsLoading(false);
  };

  profileQuestion.currentQuestionNumber = getCurrentProfileQuestionNumber(
    profileQuestion.id,
  );
  profileQuestion.totalQuestionCount = profileQuestions.length;

  return (
    <div className={styles.container}>
      <QuestionHeader question={profileQuestion} text="Profile Question " />

      {profileQuestion.image?.url && (
        <div className={styles.questionImage}>
          <LoadImage
            imageUrl={profileQuestion.image.url}
            imgAltText="question"
          />
        </div>
      )}

      <ProfileQuestionAnswers
        profileQuestionAnswers={profileQuestion.answers}
        onProfileQuestionAnswerSelected={handleSubmitProfileQuestionAnswer}
      />
    </div>
  );
};
